class MinHeap<T> {
    private heap: T[];
    private comparator: (a: T, b: T) => number;

    constructor(comparator: (a: T, b: T) => number) {
        this.heap = [];
        this.comparator = comparator;
    }

    private getParentIndex(index: number): number {
        return Math.floor((index - 1) / 2);
    }

    private getLeftChildIndex(index: number): number {
        return 2 * index + 1;
    }

    private getRightChildIndex(index: number): number {
        return 2 * index + 2;
    }

    private swap(index1: number, index2: number): void {
        [this.heap[index1], this.heap[index2]] = [this.heap[index2], this.heap[index1]];
    }

    insert(value: T): void {
        this.heap.push(value);
        this.heapifyUp();
    }

    private heapifyUp(): void {
        let index = this.heap.length - 1;
        while (index > 0) {
            const parentIndex = this.getParentIndex(index);
            if (this.comparator(this.heap[parentIndex], this.heap[index]) > 0) {
                this.swap(parentIndex, index);
                index = parentIndex;
            } else {
                break;
            }
        }
    }

    remove(): T | null {
        if (this.heap.length === 0) {
            return null;
        }
        if (this.heap.length === 1) {
            return this.heap.pop() as T;
        }
        const root = this.heap[0];
        this.heap[0] = this.heap.pop() as T;
        this.heapifyDown();
        return root;
    }

    private heapifyDown(): void {
        let index = 0;
        while (this.getLeftChildIndex(index) < this.heap.length) {
            let smallerChildIndex = this.getLeftChildIndex(index);
            if (
                this.getRightChildIndex(index) < this.heap.length &&
                this.comparator(this.heap[this.getRightChildIndex(index)], this.heap[smallerChildIndex]) < 0
            ) {
                smallerChildIndex = this.getRightChildIndex(index);
            }
            if (this.comparator(this.heap[index], this.heap[smallerChildIndex]) <= 0) {
                break;
            }
            this.swap(index, smallerChildIndex);
            index = smallerChildIndex;
        }
    }

    peek(): T | null {
        return this.heap[0] || null;
    }

    size(): number {
        return this.heap.length;
    }
}

export class TopK<T> {
    private k: number;
    private minHeap: MinHeap<T>;

    constructor(k: number, comparator: (a: T, b: T) => number) {
        this.k = k;
        this.minHeap = new MinHeap<T>(comparator);
    }

    add(value: T): void {
        if (this.minHeap.size() < this.k) {
            this.minHeap.insert(value);
        } else if (this.minHeap.peek() !== null && this.minHeap['comparator'](value, this.minHeap.peek() as T) > 0) {
            this.minHeap.remove();
            this.minHeap.insert(value);
        }
    }

    getTopK(): T[] {
        return [...this.minHeap['heap']].sort(this.minHeap['comparator']).reverse();
    }
}
