import React, { useEffect, useRef } from 'react';
import { Button, Container, Row, Col, Form, Table, ProgressBar } from 'react-bootstrap';
import { NameResult, NameResultUI, NameResultUIProps } from './generate';
import gsap from 'gsap';
import { Position, createAnimation } from './animate';

const Letters = (props: { data: {i: Position[], t: Position[]}; }) => {
  function renderPosition(pos: Position, index: number) { 
    return (
      <span className="letter" key={"letter" + index} style={{ left: pos.x + '.px', top: pos.y + 'px' }}>{pos.char}</span>
    )
  }
  return (
      <div>
          <div>
              {props.data.i.map((pos, index) => {
                  return <span className="letter" key={index} style={{ left: pos.x + '.px', top: pos.y + 'px' }}>{pos.char}</span>
              
              })}
          </div>
      </div>
  )
}

const App: React.FC = () => {
  const childRef = useRef<NameResultUIProps>(null);
  var tl = gsap.timeline();
  const [nombre, setNombre] = React.useState('');
  const [apellido, setApellido] = React.useState('');
  const [sexo, setSexo] = React.useState('hombre');
  const [type, setType] = React.useState('set');
  const [compute, setCompute] = React.useState(0);

  useEffect(() => {
    if (compute ==0)return;
    // let res = findBest(apellido, nombre, sexo, type, (cnt, total, list) => {
    //   if (cnt %1000 == 0){
        
    //     setNames([...list]); // Force a rerender by creating a new array with the updated list
    //     console.log(cnt/total*100);
    //     setNames(list);
    //   }
    
    // });
    // setNames(res);
  }, [compute]);
  

  // ...
  return (
    <Container>
      <Row className="mt-5">
        <Col id='animation'>
          {/* <Letters data={letters} /> */}
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <Form>
            <Form.Group controlId="formNombre">
              <Form.Label>Nombre de nacimiento</Form.Label>
              <Form.Control type="text" placeholder="Ingrese su nombre de nacimiento" value={nombre} onChange={(e) => setNombre(e.target.value)} />
            </Form.Group>
            <Form.Group controlId="formApellido">
              <Form.Label>Nuevo apellido</Form.Label>
              <Form.Control type="text" placeholder="Ingrese su nuevo apellido" value={apellido} onChange={(e) => setApellido(e.target.value)} />
            </Form.Group>
            <Form.Group controlId="formToggle">
              <Form.Label>Sexo</Form.Label>
              <Form.Control as="select" value={sexo} onChange={(e) => setSexo(e.target.value)}>
                <option value="hombre">Hombre</option>
                <option value="mujer">Mujer</option>
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="formToggle">
              <Form.Label>Tipo</Form.Label>
              <Form.Control as="select" value={type} onChange={(e) => setType(e.target.value)}>
                <option value="set">Mismos tipos de Letras</option>
                <option value="exact">Letras exactas</option>
              </Form.Control>
            </Form.Group>
            <Button variant="primary" onClick={() => childRef.current && childRef.current.updateData(apellido, nombre, sexo, type)}>Calcular</Button>
          </Form>
        </Col>
      </Row>
      <NameResultUI ref={childRef}></NameResultUI>
    </Container>
  );
};

export default App;