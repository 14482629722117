import {
    Button,
    ProgressBar,
    Row,
    Table
} from "react-bootstrap";
import {
    englishMaleNames
} from "./englishMaleNames";
import {
    names as N
} from "./names";
import {
    TopK
} from "./topk";
import React, {
    forwardRef,
    useImperativeHandle,
    useState
} from "react";
export type NameResult = {
    combo: string;
    diffSet: number;
    diffExact: number;
    diffSetString: string;
    diffExactString: string;
}



export type NameResultUIProps = {
    ref: any,
    updateData: (apellido: string, nombre: string, sexo: string, type: string) => {}
};

export const NameResultUI: React.FC < {
    ref: any
} > = forwardRef((props, ref) => {
    const [progress, setProgress] = React.useState(0);
    const [names, setNames] = React.useState<NameResult[]>([]);
    const [name, setName] = React.useState<string>('');
    // const [data, setData] = useState('Initial data');
    useImperativeHandle(ref, () => ({
        updateData(apellido: string, nombre: string, sexo: string, type: string) {
            setName(nombre);
            setProgress(0);
            function findBest(input: string, targetName: string, sex: string, type: string) {
                const pref = ["donovan", "juan", "david"]
                const results = new TopK < NameResult > (20, ((a, b) => {
                    if (type == "set") {
                        if (a.diffSet < b.diffSet) return 1;
                        if (a.diffSet > b.diffSet) return -1;
                        if (a.diffExact < b.diffExact) return 1;
                        if (a.diffExact > b.diffExact) return -1;
                        for (let i=0;i<pref.length;i++) {
                            if (a.combo.toLowerCase().indexOf(pref[i])>=0) {
                                if (b.combo.toLowerCase().indexOf(pref[i])>=0) {
                                    continue;
                                }
                                return 1;
                            }
                            if (b.combo.toLowerCase().indexOf(pref[i])>=0)return -1;
                        }

                        return 0;
                    }
                    if (a.diffExact < b.diffExact) return 1;
                    if (a.diffExact > b.diffExact) return -1;
                    if (a.diffSet < b.diffSet) return 1;
                    if (a.diffSet > b.diffSet) return -1;
                    for (let i=0;i<pref.length;i++) {
                        if (a.combo.indexOf(pref[i])>=0) return 1;
                        if (b.combo.indexOf(pref[i])>=0)return -1;
                    }
                    return 0;
                }));
                let s: any = {
                    "salvador": true,
                    "donald": true,
                    "aarav": true,
                    "tru": true,
                    "louie": true,
                    "davion": true,
                    "avyaan": true,
                    "victormanuel": true,
                    "davidallen": true,
                    "demontavious": true,
                    "samueldavid": true,
                    "ollivander": true,
                    "oliverjames": true,
                    "michaeldavid": true,
                    "joshuadavid": true,
                    "valeriano": true,
                    "franciscojavier": 9,
                    "davidmichael": 9,
                    "michaelvincent": 9,
                    "davidanthony": 9,
                    "davidjames": 9,
                    "marquavious": 9,
                    "stevenmichael": 9,
                    "montravious": 9,
                    "vincentmichael": 9,
                    "dontravious": 9,
                    "deontavious": 9,
                    "davidpaul": 9,
                    "devonaire": 9,
                    "jamesdavid": 9,
                    "jarquavious": 9,
                    "davidchristophe": 9,
                    "quantravious": 9,
                    "marquavion": 9,
                    "carlosjavier": 9,
                    "marquaveon": 9,
                    "jonquavious": 9,
                    "devontavius": 9,
                    "juandavid": 9,
                    "alexzavier": 9,
                    "marquevious": 9,
                    "davidjoseph": 9,
                    "donquavious": 9,
                    "jamarvion": 9,
                    "quadrevion": 9,
                    "marzavion": 9,
                    "johndavid": 7,
                    "natividad": 7,
                    "jondavid": 7,
                    "dantavious": 7,
                    "dantavius": 7,
                    "davidjohn": 7,
                    "jaquavien": 7,
                    "jadavian": 7,
                    "jaquavion": 7,
                    "jaquavian": 7
                };
                const names: string[] = [];

                function addNames(arr: string | any[]) {
                    for (let i = 0; i < arr.length; i++) {
                        let n = arr[i].toLowerCase();
                        if (!s[n]) {
                            names.push(n);
                            s[n] = true;
                        }
                    }
                    console.log(names.length, arr.length);
                }
                if (sex == "hombre") {
                    addNames(englishMaleNames);
                    addNames(N.h);
                } else {
                    addNames(N.m);
                }

                function diffName(A: string, B: string, type: string = "set") {
                    let a = A.replaceAll(" ", "").toLowerCase();
                    let b = B.replaceAll(" ", "").toLowerCase();
                    let diff = "";
                    if (type == "set") {
                        const setA: any = new Set(a);
                        const setB: any = new Set(b);
                        for (let char of setA) {
                            if (!setB.has(char)) {
                                diff += char;
                            }
                        }
                        diff += " / "
                        for (let char of setB) {
                            if (!setA.has(char)) {
                                diff += char;
                            }
                        }
                        return diff;
                    }
                    if (type == "exact") {
                        const m = new Map < string,
                            number > ();
                        for (let c of a) {
                            m.set(c, (m.get(c) ?? 0) + 1);
                        }
                        for (let c of b) {
                            m.set(c, (m.get(c) ?? 0) - 1);
                        }
                        let pos = "",
                            neg = "";
                        for (let [a, b] of Array.from(m)) {
                            if (b > 0) pos += a;
                            if (b < 0) neg += a;
                        }
                        return pos + " / " + neg;
                    }
                    return "";

                }


                const diffSet = (A: string, B: string) => {
                    let a = A.replaceAll(" ", "").toLowerCase();
                    let b = B.replaceAll(" ", "").toLowerCase();
                    const setA: any = new Set(a);
                    const setB: any = new Set(b);
                    let count = 0;
                    for (let char of setA) {
                        if (!setB.has(char)) {
                            count++;
                        }
                    }
                    for (let char of setB) {
                        if (!setA.has(char)) {
                            count++;
                        }
                    }
                    return count;
                };

                const diffExact = (A: string, B: string) => {
                    let a = A.replaceAll(" ", "").toLowerCase();
                    let b = B.replaceAll(" ", "").toLowerCase();
                    const m = new Map < string,
                        number > ();
                    for (let c of a) {
                        m.set(c, (m.get(c) ?? 0) + 1);
                    }
                    for (let c of b) {
                        m.set(c, (m.get(c) ?? 0) - 1);
                    }
                    let sum = 0;
                    for (let value of Array.from(m.values())) {
                        sum += Math.abs(value);
                    }
                    return sum;
                };



                const findBestCombinations = (names: string[], targetName: string, topN = 100) => {
                    let cnt = 0;
                    let total = names.length * (names.length - 1) / 2;
                    for (let i = 0; i < names.length; i++) {
                        for (let j = i + 1; j < names.length; j++) {
                            let combo = `${names[i]} ${names[j]} ${input}`;
                            // console.log(comb, cnt);
                            results.add({
                                combo,
                                diffSet: diffSet(targetName, combo),
                                diffExact: diffName(targetName, combo, "exact").length - 3,
                                diffSetString: diffName(targetName, combo, "set"),
                                diffExactString: diffName(targetName, combo, "exact"),
                            })
                            cnt++;
                            if (cnt % 1000 == 0) {
                                setProgress(cnt / total * 100);
                                setNames(results.getTopK());
                                console.log(cnt/total*100);
                            }
                        }
                    }

                    return results.getTopK();
                };

                const bestCombinations = findBestCombinations(names, targetName);
                console.log("Top combinations with most matching letters:");
                bestCombinations.forEach((x) => {
                    // console.log(x);
                });
                return bestCombinations;
            }

            setNames(findBest(apellido, nombre, sexo, type));
            setProgress(100);
            //   setData(newData);
        },
    }));
    function renderName(person: NameResult, index: number) {
        const animate = () => {
          window.open(`/animate.html?init=${encodeURIComponent(name.toUpperCase())}&target=${encodeURIComponent(person.combo.toUpperCase())}`);
        }
        return (
          <tr key={index}>
            <td>{person.combo.replace(/(^|\s)\S/g, (match) => match.toUpperCase())}</td>
            
            <td>{person.diffSetString}</td>
            <td>{person.diffExactString}</td>
            <td>{person.diffSet}</td>
            <td>{person.diffExact}</td>
            <td><Button variant="primary" onClick={() => animate()}>Animar</Button></td>
          </tr>
        )
      }
    return ( <Row>
        <ProgressBar now = {
            100
        }
        label = {
            `${progress}%`
        }
        />
        <Table striped hover>
        <thead>
          <tr>
            <th>Nombre</th>
            <th>(Tipo) Letras faltantes/Letras nuevas</th>
            <th>(Cantidad) Letras faltantes/Letras nuevas</th>
            <th>Tipos de letras diferentes</th>
            <th>Cantidad de letras diferentes</th>
            <th>Animar</th>
          </tr>
        </thead>
        <tbody>
          {names.map(renderName)}
        </tbody>
      </Table>
      </Row>
    )
});